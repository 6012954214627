<template>
  <footer class="footer">
    <div class="container footer__container">
      <div class="footer__row">
        <div class="footer__col">
          <router-link to="/" class="logo">
            <img class="logo__image" :src="require('@/assets/img/logo.svg')">
            <div class="logo__text">Blastools</div>
          </router-link>
        </div>
        <div class="footer__col">
          <div class="socials">
            <a class="socials__link" href="https://twitter.com/blastools/" target="_blank" rel="noopener noreferrer">
              <img :src="require('@/assets/img/icons/twitter.svg')">
            </a>
            <!-- <a class="socials__link" href="" target="_blank" rel="noopener noreferrer">
               <img :src="require('@/assets/img/icons/discord.svg')">
            </a> -->
          </div>
        </div>
        <div class="footer__col">
          <div class="logo-blast">
            <img class="logo-blast__image" :src="require('@/assets/img/logo-blast.svg')">
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>