<template>
  <header class="header">
    <div class="container header__container">
      <div class="header__wrapper">
        <router-link to="/" class="logo">
          <img class="logo__image" :src="require('@/assets/img/logo.svg')">
          <div class="logo__text">Blastools</div>
        </router-link>
        
        <div class="socials">
          <a class="socials__link" href="https://twitter.com/blastools/" target="_blank" rel="noopener noreferrer">
            <img :src="require('@/assets/img/icons/twitter.svg')">
          </a>
          <!-- <a class="socials__link" href="" target="_blank" rel="noopener noreferrer">
            <img :src="require('@/assets/img/icons/discord.svg')">
          </a> -->
        </div>

        <div class="connect" :class="{active: address}" v-if="metamask">
          <div class="small connect__address">{{ address | addressFormat }}</div>
          <div class="connect__buttons">
            <button class="btn btn_small btn_outline connect__button" @click="connect">
              <div class="btn__text">Connect wallet</div>
            </button>
            <button class="btn btn_small btn_filled disconnect__button" @click="disconnect">
              <img class="btn__icon" :src="require('@/assets/img/icons/disconnect.svg')">
              <div class="text-gradient btn__text">Disconnect</div>
            </button>
          </div>
        </div>

        <div v-if="!metamask">
          <a
            href="https://metamask.io/download/"
            target="_blank"
            rel="noopener noreferrer"
            class="btn btn_small"
            style="width: 160px"
          >
            <span class="btn__text">Download MetaMask</span>
          </a>
        </div>

      </div>
    </div>
  </header>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      address: '',
      
      metamask: Boolean(ethereum),
    }
  },
  watch: {
    "$store.state.account": function () {
      this.address = this.$store.state.account;
    },
  },
  methods: {
    ...mapActions(['connectMetamask', 'disconnectMetamask', ]),

    async connect() {
      if (!window.ethereum) {
        alert('not ethereum object')
        return
      }

      await this.connectMetamask()
    },

    disconnect() {
      this.disconnectMetamask()
    }
  },
}
</script>

<style>

</style>