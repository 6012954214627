import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/create-token',
    name: 'CreateToken',
    meta: { layout: 'main', auth: true, },
    component: () => import('../views/CreateToken.vue')
  },
  {
    path: '/tokens',
    name: 'Tokens',
    meta: { layout: 'main', auth: true, },
    component: () => import('../views/Tokens.vue')
  },
  {
    path: '*',
    name: 'Home',
    component: Home
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


import store from '../store'

router.beforeEach( async (to, from, next) => {
  const requireAuth = to.matched.some(record => record.meta.auth)

  const address = store.state.account

  if (requireAuth && !address) {
    next('/')
  } else {
    next()
  }
})


export default router
