<template>
  <main class="main">
    <div class="background">
      <img class="img img-1" :src="require('@/assets/img/backgrounds/1.webp')">
      <img class="img img-2" :src="require('@/assets/img/backgrounds/2.webp')">
      <img class="img img-3" :src="require('@/assets/img/backgrounds/3.webp')">
    </div>

    <div class="container">
      <div class="block-title">
        <h1 class="color_green title">Create your own tokens</h1>
        <p class="color_gray title-second text">with no-code, security and powered by native yield</p>
        <div class="buttons">
          <button class="btn" @click="connect" v-if="metamask && !address">
            <div class="btn__text">Connect wallet</div>
          </button>

          <router-link to="/create-token" class="btn" v-if="metamask && address">
            <div class="btn__text">Create now</div>
          </router-link>
          <router-link to="/tokens" class="btn btn_outline disabled" v-if="metamask && address">
            <div class="btn__text">Manage tokens</div>
          </router-link>

          <div v-if="!metamask">
            <a href="https://metamask.io/download/" target="_blank" rel="noopener noreferrer" class="btn">
              <span class="btn__text">Download MetaMask</span>
            </a>
          </div>
        </div>
      </div>

      <div class="block-wrapper">
        <div class="block-info">
          <div class="list">
            
            <div
              class="item"
              v-for="(item, index) in infoList"
              :key="index"
            >
              <div class="border-green"></div>
              <img class="item__icon" :src="item.icon">
              <h3 class="title-second item__title">{{ item.title }}</h3>
              <p class="color_gray item__text">{{ item.text }}</p>
            </div>
          </div>
        </div>

        <div class="block-roadmap">
          <h2 class="title">Roadmap</h2>
          <div class="list">
            <div class="item active">
              <div class="item__line"></div>
              <div class="item__step">
                <div class="border-green"></div>
                <p class="title-second">1</p>
              </div>
              <div class="item__info">
                <div class="border-green"></div>
                <p class="title-second name">Testnet</p>
                <p class="text">
                  ERC20 token creation <br>
                  Big Bang participation <br>
                  Vesting tool <br>
                  Lock tool
                </p>
              </div>
            </div>
            <div class="item">
              <div class="item__line"></div>
              <div class="item__step">
                <div class="border-green"></div>
                <p class="title-second">2</p>
              </div>
              <div class="item__info">
                <div class="border-green"></div>
                <p class="title-second name">Mainnet</p>
                <p class="text">
                  DEX integration <br>
                  Liquidity Pools creation &amp; managment <br>
                  NFT creation (ERC721)
                </p>
              </div>
            </div>
            <div class="item">
              <div class="item__line"></div>
              <div class="item__step">
                <div class="border-green"></div>
                <p class="title-second">3</p>
              </div>
              <div class="item__info">
                <div class="border-green"></div>
                <p class="title-second name">$BLAST Launch</p>
                <p class="text">
                  Airdrop distribution <br>
                  LP staking <br>
                  Native yield farming <br>
                  TBA
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions } from 'vuex'

const infoList = [
  {
    icon: require('@/assets/img/icons/lightning.svg'),
    title: 'Ease of creation',
    text: 'No-code token creation and deployment in just a few clicks'
  },
  {
    icon: require('@/assets/img/icons/lock.svg'),
    title: 'Security',
    text: 'Fully decentralized and secure smart-contract deployment'
  },
  {
    icon: require('@/assets/img/icons/db.svg'),
    title: 'Native Yield',
    text: 'Get a gas fee revenue for every transaction'
  },
]

export default {
  name: 'Home',
  data() {
    return {
      infoList,

      address: this.$store.state.account,
      metamask: Boolean(ethereum),
    }
  },
  watch: {
    "$store.state.account": function () {
      this.address = this.$store.state.account;
    },
  },
  methods: {
    ...mapActions(['connectMetamask']),

    async connect() {
      if (!window.ethereum) {
        alert('not ethereum object')
        return
      }

      await this.connectMetamask()
    },
  }
}
</script>
