import Vue from 'vue'
import Vuex from 'vuex'
const ethers = require('ethers')
import Factory from './FactoryBlastools.json'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    account: ''
  },
  mutations: {
  },
  actions: {
    async connectMetamask({ state }) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
        const account = accounts[0];
        state.account = account;
        console.log('account: ', accounts);
      } catch (err) {
        console.log('connectMetamask ERR: ', err);
      }
    },

    disconnectMetamask({ state }) {
      state.account = ''
    },

    async createToken({}, args) {
      // Check if MetaMask is installed
      if (typeof window.ethereum === 'undefined') {
        alert('MetaMask is not installed. Please install it to create tokens.');
        return;
      }

      // Create ethers provider and signer
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      // Initialize contract instance
      const contractAddress = '0x21c023e085cE99b4671a0144Eae0c8fd31C600c3'
      // const contractABI = [
      //   "function createToken(CreationData data) external payable"
      // ];
      const contractABI = Factory.abi

      const contract = new ethers.Contract(contractAddress, contractABI, signer);
      try {
        // Call createToken function on the contract
        await contract.createToken(args, { value: 1 });
        // const amountComission = 1;
        // await contract.createToken(creationData, { value: amountComission });

        // Token creation successful
        console.log('Token created successfully!');
        alert('Token created successfully!');
      } catch (error) {
        // Handle errors
        console.error('Error creating token:', error);
        alert('Error creating token');
      }
    }
  },
  modules: {
  }
})
