<template>
  <main style="position: relative;">
    <Header />
    <router-view style="min-height: 100vh"/>
    <Footer />
  </main>
</template>

<script>
import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';

export default {
  async mounted () {},
  components: {
    Header,
    Footer,
  }
};
</script>

<style>

</style>
